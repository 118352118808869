<template>
  <div class="container" style="padding-bottom:20px">
    <div class="row">
      <div class="col-sm-12">
        <h2 style="margin: 20px 0 0 0">{{ $t("grading.GRADING")}}</h2>

        <span v-if="isRoleAdmin() || isRoleTeacher()"
          >{{ $t("grading.Grade a student test")}}. (<a href="/tests/grading/addstudents"
            >{{ $t("grading.Add a test for a student to grade himself/herself")}}</a
          >)</span
        >
        <span v-else>
          {{ $t("grading.Grade your test")}}
        </span>
      </div>
    </div>
    <hr />
    <div v-if="isRoleAdmin() || isRoleTeacher()">
      <div class="title">
        <div style="margin-bottom:20px">
          <el-radio-group v-model="gradingType" size="small">
            <el-radio-button label="1">
              {{ $t("grading.Grading for a group class")}}
            </el-radio-button>
            <el-radio-button label="0">
               {{ $t("grading.Grading for a particular student")}}
            </el-radio-button>
          </el-radio-group>
        </div>
        <!-- 團班批改 -->
        <div v-if="gradingType === '1'">
          <el-form label-width="80px">
            <el-form-item :label="$t('grading.Teacher')" v-if="isRoleAdmin"  >
              <TeacherSelector
                :required="false"
                :value="teacher_user_id"
                @change="selectTeacher"
              />
            </el-form-item>
            <el-form-item :label="$t('grading.Class')" v-if="classes.length > 0">
              <el-select
                v-model="classIndex"
                placeholder="Select Classes"
                filterable
                class="select-test"
                @change="selectCourse"
              >
                <el-option
                  v-for="(test, index) in classes"
                  :key="test.id"
                  :label="test.title"
                  :value="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('grading.Test')" v-if="tests.length > 0">
              <el-select
                v-model="testIndex"
                placeholder="Select Test"
                filterable
                class="select-test"
                @change="selectTest"
              >
                <el-option
                  v-for="(test, index) in tests"
                  :key="index"
                  :label="'Test' + index + ' (' + test.title + ')'"
                  :value="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('grading.Students')"
              v-if="JSON.stringify(students) !== '{}'"
            >
              <el-select
                v-model="studentIndex"
                placeholder="Select Classes"
                filterable
                class="select-test"
                @change="selectStudent"
              >
                <el-option
                  v-for="(student, index) in students"
                  :key="index"
                  :label="
                    student.first_name +
                      ' ' +
                      student.last_name +
                      ' (' +
                      student.email +
                      ')'
                  "
                  :value="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('grading.Keyboard')"
              class="keyboard"
              v-if="form.test > 0 && form.student > 0 && form.type !== 'TOEFL'"
            >
              <el-radio-group v-model="form.keyboard" class="row">
                <div class="col-md-6">
                  <el-radio label="2">
                    <img
                      :class="form.keyboard === '2' ? 'keyboard_on' : ''"
                      :src="keyboard_url[1]"
                    />
                  </el-radio>
                </div>
                <div class="col-md-6">
                  <el-radio label="1">
                    <img
                      :class="form.keyboard === '1' ? 'keyboard_on' : ''"
                      :src="keyboard_url[0]"
                    />
                  </el-radio>
                </div>
              </el-radio-group>
            </el-form-item>
            <el-divider></el-divider>
            <div class="text-center" style="margin-bottom: 20px">
              <el-button
                type="success"
                @click="startClassGrading"
                :disabled="form.student === ''"
              >
                {{ $t('grading.Next') }}
              </el-button>
            </div>
            <!-- <el-tabs v-model="activeName">
              <el-tab-pane label="SAT & ACT" name="SAT&ACT">
                <el-form-item label="Class" v-if="classes.length > 0">
                  <el-select
                    v-model="classIndex"
                    placeholder="Select Classes"
                    filterable
                    class="select-test"
                    @change="selectCourse"
                  >
                    <el-option
                      v-for="(test, index) in classes"
                      :key="test.id"
                      :label="test.title"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Tests" v-if="tests.length > 0">
                  <el-select
                    v-model="testIndex"
                    placeholder="Select Test"
                    filterable
                    class="select-test"
                    @change="selectTest"
                  >
                    <el-option
                      v-for="(test, index) in tests"
                      :key="index"
                      :label="'Test' + index + ' (' + test.title + ')'"
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="Student"
                  v-if="JSON.stringify(students) !== '{}'"
                >
                  <el-select
                    v-model="studentIndex"
                    placeholder="Select Classes"
                    filterable
                    class="select-test"
                    @change="selectStudent"
                  >
                    <el-option
                      v-for="(student, index) in students"
                      :key="index"
                      :label="
                        student.first_name +
                          ' ' +
                          student.last_name +
                          ' (' +
                          student.email +
                          ')'
                      "
                      :value="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="Keyboard:"
                  class="keyboard"
                  v-if="form.test > 0 && form.student > 0"
                >
                  <el-radio-group v-model="form.keyboard" class="row">
                    <div class="col-md-6">
                      <el-radio label="2">
                        <img
                          :class="form.keyboard === '2' ? 'keyboard_on' : ''"
                          :src="keyboard_url[1]"
                        />
                      </el-radio>
                    </div>
                    <div class="col-md-6">
                      <el-radio label="1">
                        <img
                          :class="form.keyboard === '1' ? 'keyboard_on' : ''"
                          :src="keyboard_url[0]"
                        />
                      </el-radio>
                    </div>
                  </el-radio-group>
                </el-form-item>
                <el-divider></el-divider>
                <div class="text-center" style="margin-bottom: 20px">
                  <el-button
                    type="success"
                    @click="startClassGrading"
                    :disabled="form.student === ''"
                  >
                    Next
                  </el-button>
                </div>
              </el-tab-pane>
              <el-tab-pane label="TOEFL" name="TOEFL">
                <el-form-item label="Grading">
                  
                  <a style="color:white !important;" :href="'/toefl/testresults?type=full_mock&page=1&teacher=' + teacher_user_id">
                    <el-button type="success">TOEFL Testresults</el-button>
                  </a>
                  
                </el-form-item>
              </el-tab-pane>
            </el-tabs> -->
          </el-form>
        </div>
        <!-- 個人自主批改 -->
        <div v-if="gradingType === '0'">
          <!-- <div v-if="type === 'private'">
            <private-lesson :userExamId="user_exam_id"></private-lesson>
          </div> -->
          <el-form label-width="80px" :model="form">
            <el-form-item :label="$t('grading.Type')" v-if="gradingType === '0'">
              <el-radio-group v-model="form.type" size="small">
                <el-radio-button label="SAT">SAT</el-radio-button>
                <el-radio-button label="ACT">ACT</el-radio-button>
                <el-radio-button label="TOEFL">TOEFL</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('grading.Students')" v-if="form.type === 'TOEFL'">
              <el-select
                v-model="student"
                placeholder="Select student"
                filterable
                style="width:100%"
                @change="getToeflTest"
                no-data-text="Loading..."
              >
                <el-option
                  v-for="student in allStudents"
                  :key="student.id"
                  :label="
                    student.first_name +
                      ' ' +
                      student.last_name +
                      ' (' +
                      student.email +
                      ')'
                  "
                  :value="student.user_id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('grading.Test')" v-if="tests.length > 0">
              <el-select
                v-model="form.test"
                filterable
                class="select-test"
              >
                <el-option
                  v-for="test in tests"
                  :key="test.id"
                  :label="test.full_name + ' (' + test.title + ')'"
                  :value="test.title"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              :label="$t('grading.Keyboard')"
              class="keyboard"
              v-if="form.test !== '' && form.type != 'TOEFL'"
            >
              <el-radio-group v-model="form.keyboard" class="row">
                <div class="col-md-6">
                  <el-radio label="2">
                    <img
                      :class="form.keyboard === '2' ? 'keyboard_on' : ''"
                      :src="keyboard_url[1]"
                    />
                  </el-radio>
                </div>
                <div class="col-md-6">
                  <el-radio label="1">
                    <img
                      :class="form.keyboard === '1' ? 'keyboard_on' : ''"
                      :src="keyboard_url[0]"
                    />
                  </el-radio>
                </div>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <el-divider></el-divider>
          <div class="text-center">
            <el-button
              type="success"
              @click="startGrading"
              :disabled="form.exam_id === 0"
            >
              {{ $t("grading.Next") }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div style="margin-bottom:20px">
        <div>
          <el-form label-width="180px" :model="form">
            <!-- <el-form-item label="Type">
              <el-radio-group v-model="examType" size="small">
                <el-radio-button label="SAT">SAT</el-radio-button>
                <el-radio-button label="ACT">ACT</el-radio-button>
                <el-radio-button label="TOEFL">TOEFL</el-radio-button>
              </el-radio-group>
            </el-form-item> -->
            <el-form-item :label="$t('grading.class')">
              <el-select
                v-model="selectClass"
                placeholder="Select Class"
                filterable
                class="select-test"
              >
                <el-option
                  v-for="(test, index) in studentClasses"
                  :key="index"
                  :value="index"
                  :label="index == 'Others' ? ` * ${$t('grading.n/aIndependentMockTest')}` : index"
                >
                  {{ index == "Others" ? ` * ${$t('grading.n/aIndependentMockTest')}` : index }}
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('grading.test')">
              <el-select
                v-model="studentTest"
                placeholder="Select Test"
                filterable
                class="select-test"
              >
                <el-option
                  v-for="(test, index) in studentTests"
                  :key="index"
                  :value="test.user_exam_id"
                  :label="
                    test.which_week >= 0 && selectClass !== `Others`
                      ? `${test.exam_title} (Test ${test.which_week})`
                      : test.exam_title
                  "
                >
                  {{ test.exam_title }}
                  <span v-if="test.which_week >= 0 && selectClass !== `Others`"
                    >(Test{{ test.which_week }})</span
                  >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="selectClass !== null" style="margin-bottom:20px">
          <el-alert title="" type="warning" :closable="false">
            {{ $t("grading.canNotFindYouTestToGrade1") }}
            <b
              class="text-success"
              style="cursor:pointer"
              @click="showFeedback = true"
              >{{ $t("grading.canNotFindYouTestToGrade2") }}</b
            >
            {{ $t("grading.canNotFindYouTestToGrade3") }}
          </el-alert>
          <el-dialog
            :title="$t('grading.requestPermissionToGrade')"
            :visible.sync="showFeedback"
            width="50%"
          >
            <div>
              <el-form
                ref="form"
                :model="feedback"
                :label-position="isPhone ? 'left' : 'right'"
              >
                <el-form-item :label="$t('grading.test')">
                  <el-radio-group
                    v-model="feedback.type"
                    size="small"
                    style="width:100%;margin-bottom: 10px"
                    @change="feedback.test = null"
                  >
                    <el-radio-button label="SAT">SAT</el-radio-button>
                    <el-radio-button label="ACT">ACT</el-radio-button>
                    <!-- <el-radio-button label="TOEFL">TOEFL</el-radio-button> -->
                  </el-radio-group>
                  <!-- <el-select v-model="feedback.test" style="width:100%" placeholder="Select Test"></el-select> -->
                  <el-select
                    v-model="feedback.test"
                    placeholder="Select Test"
                    filterable
                    class="select-test"
                  >
                    <el-option
                      v-for="test in studentSelectExams[feedback.type]"
                      :key="test.id"
                      :label="test.full_name + ' (' + test.title + ')'"
                      :value="test.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <!-- <el-form-item label="選擇聯繫人：" style="margin-bottom:0">
                <el-radio-group v-model="feedback.people">
                  <el-radio label="admin" style="line-height:40px"
                    >Ivy Way 管理人員</el-radio
                  >
                  <el-radio label="teacher" style="line-height:40px" v-if="selectClass !== 'Others'"
                    >老師</el-radio
                  >
                </el-radio-group>
              </el-form-item> -->
                <el-form-item label="選擇老師：">
                  <TeacherSelector
                    :required="false"
                    :value="feedback.teacher_id"
                    @change="selectFeedbackTeacher"
                  />
                </el-form-item>
                <el-form-item>
                  <div class="text-right">
                    <el-button @click="showFeedback = false">
                      取消
                    </el-button>
                    <el-button
                      type="primary"
                      @click="postFeedback"
                      :disabled="
                        feedback.teacher_id === null || feedback.test === null
                      "
                    >
                      提交
                    </el-button>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </el-dialog>
        </div>
        <div class="text-center">
          <el-button
            type="success"
            @click="gradingStudent"
            :disabled="studentTest === null"
            >{{ $t("grading.inputAnswers") }}</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import TOEFL from "@/apis/tests.js";
import role from "@/mixins/role.js";
import TeacherSelector from "@/components/selector/Teacher.vue";

export default {
  metaInfo() {
    return {
      title: "Grading - Ivy-Way Academy"
    };
  },

  components: { TeacherSelector },

  mixins: [role],

  props: [],
  data() {
    return {
      form: {
        type: "SAT",
        test: 0,
        student: "",
        keyboard: "2"
      },
      tests: [],
      teachers: [],
      classes: [],
      allStudents: [],
      students: [],
      student: null,
      classIndex: null,
      studentIndex: null,
      testIndex: null,
      gradingType: this.type,
      studentGradingType: 0,
      teacher_user_id: null,
      activeName: "SAT&ACT",
      selectClass: null,
      studentTest: null,
      studentClasses: [],
      studentTests: [],
      showFeedback: false,
      feedback: {
        type: "SAT",
        test: null,
        teacher_id: null
      },
      feedback_teacher_user_id: null,
      studentSelectExams: {
        SAT: [],
        ACT: []
      }
    };
  },
  computed: {
    ...mapState("user", ["profile"]),
    type() {
      let type = null;
      if (this.$route.query.type) {
        type = this.$route.query.type;
      }
      return type;
    },
    keyboard_url() {
      let url = [];
      if (this.form.type.toUpperCase() === "SAT") {
        url[0] =
          "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/tests/SAT_type2.jpg";
        url[1] =
          "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/tests/SAT_type1.jpg";
      }
      if (this.form.type.toUpperCase() === "ACT") {
        url[0] =
          "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/tests/ACT_type1.jpg";
        url[1] =
          "https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/tests/ACT_type2.jpg";
      }
      return url;
    }
  },
  watch: {
    "form.type": {
      handler() {
        if (this.gradingType === "0") {
          this.tests = [];
          this.getTests();
          this.form.test = null;
        }
      }
    },
    selectClass(val) {
      // this.feedback.people = "admin";
      this.studentTest = null;
      this.studentTests = this.studentClasses[val];
    },
    gradingType(val) {
      if (val === "0") {
        this.getTests();
        this.form.test = null;
      } else {
        this.gradingClass();
        this.teacher_user_id = null;
      }
    }
  },

  mounted() {
    if (this.isRoleAdmin() || this.isRoleTeacher()) {
      let type = null;
      let typeStr = "";
      this.getStudents();
      if (this.$route.query.teacher_id) {
        this.teacher_user_id = parseInt(this.$route.query.teacher_id);
        this.getGroupCourses(this.teacher_user_id);
      }
      if (this.$route.query.type) {
        typeStr = this.$route.query.type;
      }
      if (typeStr === "private") {
        type = "0";
      }
      if (typeStr === "groupCourse") {
        type = "1";
      }
      this.gradingType = type;
      if (this.gradingType === "0") {
        this.getTests();
      } else {
        this.gradingClass();
      }
    } else {
      this.getTests(this.feedback.type);
      this.studentGetExams();
      this.getStudentTests();
    }
  },

  methods: {
    async postFeedback() {
      await TOEFL.postFeedback({
        teacher_id: this.feedback.teacher_id,
        exam_id: this.feedback.test
      });
      this.$message({
        message: "申請成功，請耐心等待反饋結果。Thanks♪(･ω･)ﾉ",
        type: "success"
      });
      this.showFeedback = false;
    },
    getToeflTest() {},
    async getStudentTests() {
      // const res = await TOEFL.getStudentExams(this.profile.id);
      // this.studentTests = res.user.user_exams;
      const res = await TOEFL.getClassesExams();
      let classes = {};
      for (let i in res) {
        if (i === "user_exams") {
          classes["Others"] = res[i];
        } else {
          if (res[i].length > 0) {
            res[i].forEach(element => {
              classes[element.student_class_title] =
                element.student_class_ticket_exams;
            });
          }
        }
      }
      this.studentClasses = classes;
    },
    gradingStudent() {
      this.routerPush({
        name: "GradingPrivate",
        query: {
          user_exam_id: this.studentTest
        }
      });
    },
    async getTests(type) {
      let tests = {};
      let selectType = "SAT";
      if (type) {
        selectType = type;
      } else {
        selectType = this.form.type;
      }
      if (selectType == "SAT") {
        tests = await TOEFL.getSats();
        tests = tests["sats"];
      } else if (selectType == "ACT") {
        tests = await TOEFL.getActs();
      } else if (selectType == "TOEFL") {
        tests = [
          {
            full_name: "TPO 1",
            title: "tpo1"
          },
          {
            full_name: "TPO 2",
            title: "tpo2"
          },
          {
            full_name: "TPO 3",
            title: "tpo3"
          }
        ];
      }
      this.tests = tests;
    },
    gradingClass() {
      this.classes = [];
      this.students = {};
      this.tests = [];
      this.form.test = "";
    },
    // 老師給個人批改
    async startGrading() {
      let url_name = "Grading" + this.form.type;
      let test = this.form.test;
      // SAT ACT
      if (this.form.type === "SAT" || this.form.type === "ACT") {
        let type = "sats";
        if (this.form.type == "SAT") {
          type = "sats";
        } else if (this.form.type == "ACT") {
          type = "acts";
        }
        let exam_id = await TOEFL.getExamId(type, {
          title: test
        });
        this.routerPush({
          name: url_name,
          query: {
            keyboard: this.form.keyboard,
            exam_id: exam_id
          }
        });
      }
      if (this.form.type === "TOEFL") {
        console.log(1);
      }
    },
    startClassGrading() {
      if (this.form.type !== "TOEFL") {
        let url_name = "Grading" + this.form.type;
        this.routerPush({
          name: url_name,
          query: {
            keyboard: this.form.keyboard,
            exam_id: this.form.test,
            user_id: this.form.student,
            classIndex: this.classIndex,
            teacher_id: this.teacher_user_id
          }
        });
      }
    },
    async getStudents() {
      const students = await TOEFL.getStudents();
      this.allStudents = students;
    },
    async studentGetExams() {
      const exams = await TOEFL.studentGetExams();
      this.studentSelectExams.SAT = exams.exams["2"];
      this.studentSelectExams.ACT = exams.exams["3"];
    },
    selectTeacher(val) {
      this.teacher_user_id = val.id;
      this.getGroupCourses(this.teacher_user_id);
    },
    selectFeedbackTeacher(val) {
      this.feedback.teacher_id = val.id;
    },
    async getGroupCourses(teacher_uid) {
      this.gradingClass();
      this.form = {
        type: "SAT",
        test: 0,
        student: "",
        keyboard: "2"
      };
      this.classIndex = null;
      const res = await TOEFL.getGroupCourses({
        teacher_id: teacher_uid
      });
      if (res.session_classes != undefined) {
        this.classes = res.session_classes;
      } else {
        this.$message({
          message: "該老師暫時沒有正在進行中的課程。",
          type: "warning"
        });
        this.classes = [];
      }
    },
    selectCourse(index) {
      this.students = this.classes[index].students;
      this.tests = this.classes[index].exams;
      this.testIndex = null;
      this.studentIndex = null;
    },
    selectStudent(index) {
      this.form.student = this.students[index].user_id;
    },
    selectTest(index) {
      this.form.test = this.tests[index].exam_id;
      this.form.type = this.tests[index].exam_category_title.toUpperCase();
    }
  }
};
</script>

<style scoped>
.select-test {
  width: 100%;
}
.keyboard img {
  width: 100%;
  opacity: 0.2;
}
.keyboard >>> .el-radio__label {
  padding: 0;
}
.keyboard >>> .el-radio__input {
  display: none;
}
img.keyboard_on {
  opacity: 1;
}
::v-deep .el-tabs__nav {
  margin-left: 80px;
}
::v-deep .el-tabs__header {
  margin-bottom: 30px;
}
</style>
